import React from "react"
import { Link } from "gatsby"

import aliIllustration from '../assets/ali-images.png';
import ArrowRight from '../assets/arrow-right.svg';

import Layout from "../components/layout"
import '../components/home.scss';
import '../components/projects.scss';

export default function Home() {
  return (
    <Layout>
      <section className="section-no-margin">
        <div className="row">
          <div className="align-center">
            <img className="col-10 margin-btm-md WI-header col-100" src={aliIllustration} alt="Illustrations of ali designing and cooking" />
            <div className="align-left">
              <h1 className="header--medium margin-btm-xxs">Some of my Favorite Things</h1>
              <div className="flex flex-break-sm">
                <div className="col-7 divider-coral col-100 mobile-margin-btm-xs">
                  <p>Illustrations from my last website, portraying myself doing some of my favorite things.</p>
                </div>
                <div className="flex flex-col col-5 col-100">
                  <div className="margin-btm-xxs">
                    <label className="header-label">Role:</label>
                    <span className="text-sm">Illustrator</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth">
        <div className="footer-projects footer-MTmarketing">
          <div className="wrapper-project-footer flex-center column">
            <div className="section-internal flex flex-row space-between">
              <div>
                <span className="next-previous">Next Project</span>
                <Link to="/MT-marketing/">
                  <h3 className="header--medium">Mariana Tek - Marketing Materials</h3>
                </Link>
                <span className="description">Design & UX</span>
              </div>
              <Link to="/MT-marketing/">
                <img className="next-arrow" src={ArrowRight} alt="arrow" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
